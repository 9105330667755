


import {React,useRef} from "react";
import { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "./Style/homepage.css";
import img1 from './carousel-1.jpg';
import img2 from './carousel-2.jpg';
import img4 from './WhatsApp Image 2025-02-14 at 11.02.34 PM.jpeg';

function Home() {

  const videoRef = useRef(null);

  const handleVideoLoad = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0.2; // Start from 0.2 seconds
    }
  };


  const categories = [
        { name: "ReactJS", imgSrc: "img/react.png" },
        { name: "NodeJS", imgSrc: "img/node.webp" },
        { name: "ExpressJS", imgSrc: "img/express-js.png" },
        { name: "MongoDB", imgSrc: "img/mongo.png" },
        { name: "Django", imgSrc: "img/django.png" },
        { name: "HTML", imgSrc: "img/html.png" },
        { name: "CSS", imgSrc: "img/css.svg" },
        { name: "Javascript", imgSrc: "img/js.png" },
      
        { name: "Python", imgSrc: "img/cat3.png" },
        { name: "Java", imgSrc: "img/cat4.png" },
        { name: "Web Design", imgSrc: "img/cat5.png" },
        
        { name: "MySQL", imgSrc: "img/cat7.png" },
        
      ];
    
      const services = [
        {
          icon: "img/icon1.png",
          title: "Job Opportunities",
          description:
            "Gain hands-on experience in MERN Stack Development, Django Development, and Digital Marketing with NIRC.",
        },
        {
          icon: "img/icon2.png",
          title: "Affordable Learning",
          description:
            "Access top-quality internships at a minimal cost and enhance your skills with real-world projects.",
        },
        {
          icon: "img/icon3.png",
          title: "Flexible Work Environment",
          description:
            "Learn and work at your own pace with remote and hybrid internship options available across India.",
        },
        {
          icon: "img/icon4.png",
          title: "Certified Internship Programs",
          description:
            "Receive an industry-recognized certificate upon completing your internship with NIRC.",
        },
      ];
    
    
      const faqData = [
        {
          question: "What is NIRC?",
          answer:
            "NIRC is a leading organization based in Bangalore that provides internship opportunities across India in fields like MERN Stack Development, Django Development, and Digital Marketing.",
        },
        {
          question: "Why should I choose NIRC for an internship?",
          answer:
            "NIRC offers real-world projects, expert mentorship, and career-oriented training in high-demand fields to prepare you for success.",
        },
        {
          question: "How many internships can I apply for at NIRC?",
          answer:
            "You can apply for multiple internships, such as MERN Stack Development, Django Development, and Digital Marketing, based on your interests.",
        },
        {
          question: "How can I enroll for an internship at NIRC?",
          answer:
            "Visit our website, create an account, and apply for the internship of your choice. The process is simple and hassle-free!",
         },
         {
           question: "What are the benefits of doing an internship at NIRC?",
           answer:
             "You’ll gain hands-on experience, work on live projects, receive mentorship from experts, and earn a certification to boost your career prospects.",
         },
       ];



       const [clickedIndex, setClickedIndex] = useState(null);

       useEffect(() => {
         const cards = document.querySelectorAll(".why-card");
         cards.forEach((card, index) => {
           card.style.animation = `fadeIn 0.8s ease-in-out ${index * 0.3}s forwards`;
         });
       }, []);
     
       const handleCardClick = (index) => {
         setClickedIndex(index);
       };
  return (
    <div>
      <div className="container-fluid p-0 mb-4">
        <OwlCarousel
          className="owl-carousel header-carousel position-relative"
          items={1}
          loop
          autoplay
          autoplayTimeout={5000}
          animateOut="fadeOut"
          animateIn="fadeIn"
          nav={false}
          dots={true}
        >
         {/* Carousel Item 1 */}
                    <div className="owl-carousel-item position-relative">
            <img
              className="img-fluid"
              src={img1}
              alt="Carousel 1"
            />
            <div
              className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
              style={{ background: "rgba(24, 29, 56, .7)" }}
            >
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-sm-10 col-lg-8">
                    <h5
                      className="text-uppercase mb-3 animated slideInDown"
                      style={{ color: "#507ad5" }}
                    >
                     Best Online/Offline software Training

                    </h5>
                    <h1 className="display-3 text-white animated slideInDown">
                    Learn Job ready skills Industry Expert
                    </h1>
                    <p className="text-white mb-4 pb-2">
                      Explore a wide range of courses designed to enhance your expertise in technology. Start learning today!

                    </p>
                    <a
                      href="
                      /aboutus"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      Read More
                    </a>
                    <a
                      href="/contact"
                      className="btn btn-light py-md-3 px-md-5 animated slideInRight"
                    >
                     Contact Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Carousel Item 2 */}
           <div className="owl-carousel-item position-relative">
             <img
              className="img-fluid"
              src={img2}
              alt="Carousel 2"
            />
            <div
              className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
              style={{ background: "rgba(24, 29, 56, .7)" }}
            >
              <div className="container -mt-14">
                <div className="row justify-content-start">
                  <div className="col-sm-10 col-lg-8">
                    <h5
                      className="text-uppercase mb-3 animated slideInDown"
                      style={{ color: "#507ad5" }}
                    >
                      Welcome to NextGen Internship & Research center(NIRC)
                    </h5>
                    <h1 className="display-3 text-white animated slideInDown">
                      Interactive Learning Experience
                    </h1>
                    <p className="text-white mb-4 pb-2">
                      Engage with interactive lessons, quizzes, and projects.
                      Experience hands-on learning that keeps you motivated and
                      inspired.
                    </p>
                    <a
                      href="/aboutus"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      Read More
                    </a>
                    <a
                      href="/contact"
                      className="btn btn-light py-md-3 px-md-5 animated slideInRight"
                    >
                      Contact Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>

      <div className="invest-section container-xxl py-5">
       <div className="container">
         {/* Header section */}
         <div className="row g-2 text-center">
           <div className="col-12 wow fadeInUp" data-wow-delay="0.3s">
             <h1 className="title" style={{fontSize:'34px',color:'#507ad5'}}>
             Campus to Corporate Program
             </h1>
             <p className="mb-5 description" style={{color:'black', fontSize:'24px'}}>
             Explore a wide range of courses designed to enhance your expertise in technology, business, arts, and more. Start learning today!

             </p>
           </div>
         </div>

         {/* Service items */}
         <div className="row g-4">
           {services.map((service, index) => (
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay={`${0.1 + index * 0.2}s`}
              key={index}
            >
              <div className="service-item"  >
                <div className="p-4">
                  <img
                    src={service.icon}
                    alt={service.title}
                    width="60px"
                    className="mb-4"
                  />
                  <h5 className="mb-3" style={{fontSize:'24px'}}>{service.title}</h5>
                  <p>{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            {/* Image Section */}
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid position-absolute w-100 h-100"
                  src={img4}
                  alt="About Internshipwala"
                  style={{ objectFit: "fit",borderRadius:'20px' }}
                />
              </div>
            </div>

            {/* Text Content Section */}
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start pe-3">
                About Us
              </h6>
              <h1 className="mb-4" style={{ color: "#507ad5" }}>
                Welcome to NIRC
              </h1>
                          <p className="mb-4">
               NIRC, based in Bangalore, is a leading provider of industry-focused
               internships across India. We specialize in{" "}
               <strong>MERN Stack Development, Django Development, and Digital Marketing</strong>,
               offering hands-on experience to help students and professionals build real-world skills.
             </p>
             <p className="mb-4">
               Our mission is to bridge the gap between education and industry by providing{" "}
               <strong>practical training</strong>, mentorship from experts, and opportunities to
               work on live projects. At NIRC, we believe in{" "}
               <strong>learning by doing</strong>, ensuring that every intern is industry-ready.
             </p>
              {/* List of Features */}
              <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right me-2"></i>Expert Instructors
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right me-2"></i> Interactive
                    Sessions
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right me-2"></i>Comprehensive
                    Course Catalog
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right me-2"></i>Community
                    Engagement
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right me-2"></i>Personalized
                    Learning Paths
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right me-2"></i>Certification and
                    Recognition
                  </p>
                </div>
              </div>

              {/* Read More Button */}
              <a className="btn text-light py-2 px-5 mt-2 text-2xl " style={{backgroundColor:'#507ad5',borderRadius:'10px' ,fontSize:'18px'}} href="/aboutus">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>

      

      <div className="container-xxl py-5 category">
        <div className="container">
          {/* Title Section */}
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center px-3">
              Categories
            </h6>
            <h1 className="mb-5" style={{ color: "#507ad5" }}>
              Popular Topics to Explore
            </h1>
          </div>

          {/* Categories Grid */}
          <div className="row g-2 m-2">
            {categories.map((category, index) => (
              <div className="col-lg-3 col-md-6 text-center" key={index}>
                <div
                  className="content shadow p-3 mb-2 wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <img
                    src={category.imgSrc}
                    className="img-fluid"
                    alt={category.name}
                  />
                  <h5 className="my-2">
                    <a href="#" className="text-center">
                      {category.name}
                    </a>
                  </h5>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      

      <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center px-3">
              Internships
            </h6>
            <h1 className="mb-5" style={{ color: "#507ad5" }}>
             Internships available in NIRC
            </h1>
          </div>
      <div className="cards-container">
          <div className="card">
            <div className="card-image" style={{ backgroundImage: `url('https://demo2.themelexus.com/editech/wp-content/uploads/2021/03/services-3.jpg')` }}>
              <div className="card-gradient"></div>
              <div className="card-title">
                <i className="card-icon fa fa-code"></i> {/* Icon for Full Stack Web Development */}
                <h3 style={{color:'white'}}>MERN Stack Development</h3>
              </div>
            </div>
            <div className="card-hover-content">
              <div className="card-icon-text">
                <p>Gain hands-on experience in building dynamic web applications using MongoDB, Express.js, React.js, and Node.js, working on both front-end and back-end development.</p>
              </div>
              <a href='/web'>
              <button className="card-button">Learn More</button></a>
            </div>
          </div>

          <div className="card">
            <div className="card-image" style={{ backgroundImage: `url('https://demo2.themelexus.com/editech/wp-content/uploads/2021/03/services-5.jpg')` }}>
              <div className="card-gradient"></div>
              <div className="card-title">
                <i className="card-icon fa fa-cogs"></i> {/* Icon for Data Science with Machine Learning */}
                <h3 style={{color:'white'}}>Python Django Web Development</h3>
              </div>
            </div>
            <div className="card-hover-content">
              <div className="card-icon-text">
                <p>Work on developing backend solutions with Python and Django, focusing on database management, API creation, and server-side logic for web applications.</p>
              </div>
             <a href='/python'> <button className="card-button">Learn More</button></a>
            </div>
          </div>

          <div className="card">
            <div className="card-image" style={{ backgroundImage: `url('https://demo2.themelexus.com/editech/wp-content/uploads/2021/03/services-4.jpg')` }}>
              <div className="card-gradient"></div>
              <div className="card-title">
                <i className="card-icon fa fa-microchip"></i> {/* Icon for Embedded System */}
                <h3 style={{color:'white'}}>Digital Marketing Internship</h3>
              </div>
            </div>
            <div className="card-hover-content">
              <div className="card-icon-text">
                <p>Assist in creating and executing digital marketing campaigns, including SEO, social media management, and content optimization, while analyzing campaign performance</p>
              </div>
              <a href='/digital'><button className="card-button">Learn More</button></a>
            </div>
          </div>
        </div>

        

        
    <div className="nirc-section">
      <div className="content-container">
        {/* Left Side - Video */}
        <div className="video-container">
          <video ref={videoRef} 
            src="/w.mp4" 
           
            loop 
            controls 
            playsInline 
            onLoadedMetadata={handleVideoLoad} ></video>
        </div>

        {/* Right Side - Text */}
        <div className="text-container" style={{maxWidth:'1200px'}}>
          <h2 style={{textAlign:'center'}}>Apply For Internships</h2>
          <p style={{textAlign:'justify'}}>
          Ready to gain hands-on experience in a fast-paced environment? Internships provide an invaluable opportunity to build your professional network, enhance your skills, and make a real impact. At NIRC, we offer internships across various fields, ensuring you get the chance to work on exciting projects that will shape your future career.
          </p>
          <p  style={{textAlign:'justify'}}>
            Join our internship programs to gain practical experience, work on live projects, and receive mentorship from industry experts.
          </p>
          
        </div>
      </div>
    </div>

    <div className="container my-5 why-choose-us">
    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center px-3">
              Features
            </h6>
            <h1 className="mb-5" style={{ color: "#507ad5" }}>
             Why Choose us?
            </h1>
          </div>
      <div className="row">
        {[
          {
            icon: "fa-lightbulb",
            title: "Innovative Learning",
            text: "Our internships provide hands-on learning with cutting-edge technologies, helping you stay ahead of industry trends.",
            bg: "#fef5da",
          },
          {
            icon: "fa-users",
            title: "Collaborative Environment",
            text: "Work with industry experts and collaborate with like-minded individuals to build real-world projects.",
            bg: "#e3f2fd",
          },
          {
            icon: "fa-briefcase",
            title: "Job Readiness",
            text: "Gain skills that are highly valued in the job market, making you job-ready for the tech industry.",
            bg: "#e8f5e9",
          },
        ].map((item, index) => (
          <div className="col-md-4" key={index}>
            <div
              className={`why-card ${clickedIndex === index ? "clicked" : ""}`}
              style={{ backgroundColor: item.bg }}
              onClick={() => handleCardClick(index)}
            >
              <div className="why-card-title">
                <i  className={`fa ${item.icon}`}></i>
                <h3 style={{color:'black'}}>{item.title}</h3>
              </div>
              <p className="why-card-text" style={{color:'black'}}>{item.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
      

    </div>
  );
}

export default Home;
