import React from "react";
import img from './sunilf.jpg'
import img1 from './WhatsApp Image 2025-02-14 at 5.58.23 PM.jpeg'
import img3 from './WhatsApp Image 2025-02-14 at 5.58.23 PM.jpeg'

function AboutUs() {
  return (
    <div>
      {/* Header Start */}
      <div
        style={{
          background: `linear-gradient(rgba(24, 29, 56, 0.7), rgba(24, 29, 56, 0.7)), url("img/carousel-1.jpg")`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="container-fluid bg-primary py-5 mb-5 page-header"
      >
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                About Us
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a className="text-white" href="/">
                      Home
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    About
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* Header End */}

      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            {/* Left Side - Image */}
            <div className="col-lg-6">
              <img
                src={img3}
                alt="About Us"
                className="img-fluid "
                style={{height:'400px', borderRadius:'20px'}}
              />
            </div>
            {/* Right Side - Text */}
            <div className="col-lg-6">
              <h6 className="section-title bg-white text-start pe-3">
                About Us
              </h6>
              <h1 className="mb-4" style={{ color: "#507ad5" }}>
                Internshipwala: Empowering the Next Generation of Innovators
              </h1>
              <p className="mb-4" style={{textAlign:'justify'}}>
                At NIRC, we don’t just teach technology—we shape future-ready
                professionals. As the best center for job-oriented training, we
                offer hands-on development in the latest technologies, including
                Python, Django, ReactJS, and Node.js.
              </p>
              <p className="mb-4" style={{textAlign:'justify'}}>
                Our approach is simple yet powerful: real-world projects, expert
                mentorship, and a curriculum designed to meet industry demands.
                Whether you're a student or a professional looking to upskill,
                NIRC equips you with the practical experience and technical
                expertise to excel in today's competitive job market. Join us
                and transform your learning into a career!
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      {/* Vision Section */}
      <div className="container py-5">
      <div className="row g-5 align-items-center">
          {/* Left - Image */}
          
          
          {/* Right - Text */}
          <div className="col-lg-6">
            <h2 className="mb-3" style={{ color: "#507ad5" }} >Our Vision</h2>
            <p style={{textAlign:'justify'}}> 
            At NIRC, our vision is to bridge the gap between education and industry by providing hands-on, job-oriented training that empowers students to excel in the ever-evolving tech landscape. We strive to be the leading technology and research center where innovation meets practical learning, ensuring every student gains the skills, confidence, and experience needed to succeed in the modern workforce.

            </p>
          </div>
         
          <div className="col-lg-6">
            <img
              src="img/about.jpg"
              alt="Our Vision"
              className="img-fluid"
              style={{borderRadius:'20px'}}
            />
        </div>
        </div>
      </div>

      {/* Mission Section */}
      <div className="container py-5">
        <div className="row g-5 align-items-center flex-row-reverse">
          {/* Right - Image */}
          
          {/* Left - Text */}
          <div className="col-lg-6">
            <h2 className="mb-3" style={{ color: "#507ad5" }}>Our Mission</h2>
            <p style={{textAlign:'justify'}}>
            Our mission is to produce 100% successful students by equipping them with hands-on training in the latest technologies. We are committed to bridging the gap between academic learning and industry requirements by providing real-world projects, expert mentorship, and job-oriented skills. At NIRC, we ensure that every student gains the confidence, competence, and practical experience needed to excel in their careers and become industry-ready professionals.

            </p>
          </div>
          <div className="col-lg-6">
            <img
              src='https://img.jagranjosh.com/imported/images/E/Articles/how-to-find-internships.webp'
              alt="Our Mission"
              className="img-fluid "
              style={{borderRadius:'20px'}}
            />
          </div>
        </div>
      </div>

      {/* Director’s Message */}
      <div className="container py-5">
        <div className="row align-items-center g-5">
          {/* Left Side - Image */}
        
          {/* Right Side - Message */}
          <div className="col-lg-8" style={{maxWidth:'650px'}}>
            <h3 className="mb-4" style={{ color: "#507ad5" }}>Director’s Message – Sunil Kumar</h3>
            <p style={{textAlign:'justify'}}>
              At NIRC, our goal is to bridge the gap between academic learning
              and industry expectations by providing hands-on, job-oriented
              training in the latest technologies.
            </p>
            <p style={{textAlign:'justify'}}>
              With 20 years of experience in the software industry and 10 years
              in teaching and internships, I have had the privilege of mentoring
              and training over 5,000 students across Karnataka, many from top
              institutions.
            </p>
            <p style={{textAlign:'justify'}}>
              More than 1,000 of my students have successfully secured positions
              in leading companies, and they remain my greatest assets.
            </p>
            <p style={{textAlign:'justify'}}>
              My journey began in the corporate world, working with MNCs like
              Novatek International, where I gained valuable industry insights.
              Later, I founded RCS Technology, focusing on providing real-world
              tech training. Over the years, I have served as a guest lecturer
              in multiple colleges and conducted seminars and workshops in
              numerous institutions, sharing knowledge and helping students
              build their careers.
            </p>
            <p style={{textAlign:'justify'}}>
              At NIRC, we are committed to transforming education through
              practical learning. Our programs empower students with real-world
              experience, industry-relevant skills, and the confidence to
              succeed in their careers. Join us and take the first step toward a
              brighter future!
            </p>
            <p className="mt-4">
              – <strong>Sunil Kumar</strong> <br />
              Director, NIRC
            </p>
          </div>

          <div className="col-lg-4 text-center">
            <img
              src={img}
              alt="Director Sunil Kumar"
              className="img-fluid "
              style={{ maxWidth: "490px",height:'490px',borderRadius:'20px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
