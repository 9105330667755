import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Style/navbar.css";

function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [navbarFixed, setNavbarFixed] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Toggle mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Close menu when a link is clicked
  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
    setIsDropdownOpen(false);
  };

  // Toggle dropdown menu
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Add scroll event listener to fix navbar on scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setNavbarFixed(true);
      } else {
        setNavbarFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <header>
        <nav
          className={`navbar navbar-expand-lg navbar-light bg-light ${
            navbarFixed ? "navbar-fixed" : ""
          }`}
        >
          <div className="container-fluid d-flex justify-content-between align-items-center">
            {/* Logo and project name */}
            <div className="navbar-brand logotransform d-flex align-items-center">
              <img
                src="./img/logo1.png"
                alt="internship"
                className="logo-img"
                style={{ height: "60px", width: "60px" }}
              />
              <span
                className="ms-2 project-name"
                style={{ color: "#507ad5" }}
              >
                NIRC
              </span>
            </div>

            {/* Toggle button for mobile view */}
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleMobileMenu}
            >
              <i
                className={`fas ${isMobileMenuOpen ? "fa-times" : "fa-bars"}`}
              ></i>
            </button>

            {/* Menu items */}
            <div
              className={`collapse navbar-collapse ${
                isMobileMenuOpen ? "show" : ""
              } justify-content-end`}
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    to="/"
                    className="nav-link"
                    onClick={closeMobileMenu}
                    style={{ color: "black" }}
                  >
                    HOME
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/aboutus"
                    className="nav-link"
                    onClick={closeMobileMenu}
                    style={{ color: "black" }}
                  >
                    ABOUT US
                  </Link>
                </li>

                {/* Dropdown Menu for Internships */}
                <li
                  className="nav-item dropdown"
                  onMouseEnter={toggleDropdown}
                  onMouseLeave={toggleDropdown}
                >
                  <Link
                    to="#"
                    className="nav-link dropdown-toggle"
                    style={{ color: "black" }}
                  >
                    INTERNSHIPS
                  </Link>

                  {isDropdownOpen && (
                    <ul
                      className="dropdown-menu"
                      style={{
                        display: "block",
                        position: "absolute",
                        background: "white",
                        listStyle: "none",
                        padding: "10px",
                        boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                        minWidth: "200px",
                      }}
                    >
                      <li>
                        <Link
                          to="/web"
                          className="dropdown-item"
                          style={{
                            color: "black",
                            textDecoration: "none",
                            padding: "8px 15px",
                            display: "block",
                          }}
                          onClick={closeMobileMenu}
                        >
                          MERN Stack Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/python"
                          className="dropdown-item"
                          style={{
                            color: "black",
                            textDecoration: "none",
                            padding: "8px 15px",
                            display: "block",
                          }}
                          onClick={closeMobileMenu}
                        >
                          Django Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/digital"
                          className="dropdown-item"
                          style={{
                            color: "black",
                            textDecoration: "none",
                            padding: "8px 15px",
                            display: "block",
                          }}
                          onClick={closeMobileMenu}
                        >
                          Digital Marketing
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>

                <li className="nav-item">
                  <Link
                    to="/contact"
                    className="nav-link"
                    onClick={closeMobileMenu}
                    style={{ color: "black" }}
                  >
                    CONTACT US
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default Header;
